
/* eslint-disable */

exports.onRouteUpdate = () => {
    const event = new Event('route_update');
    document.dispatchEvent(event);
};

exports.onClientEntry = () => {
    // setTimeout(()=>{
    //     initChat();
    // },2000);    
}
